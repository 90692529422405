import React, { useContext } from 'react'

import { useParams } from 'react-router-dom'

import { DuplicateParticipantSelectionContext } from 'App'
import DuplicatedContactInfoScreen from 'components/DuplicatedContactInfoScreen'
import ErrorState from 'components/ErrorState'
import Loader from 'components/lib/Loader'
import { PublicQuestions } from 'components/Survey/Survey'
import SurveyRouter from 'components/SurveyRouter'
import ReactGA from 'config/googleAnalytics'
import {
  SurveyByParticipantIdQuery,
  useSurveyByParticipantIdQuery,
  ParticipantSourceTypeInput,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { TranslationKey, useTranslations } from 'locales'
import { LOCTypeEnum } from 'utils/generatedEnums'

const setAnalyticsDimensions = (
  participantId: string,
  analyticsIdentifiers: SurveyByParticipantIdQuery['participantAnalyticsIdentifiers'],
  productType: SurveyProductTypeEnum,
  linkSource?: string,
) => {
  if (ReactGA && analyticsIdentifiers) {
    ReactGA.set({
      userId: participantId,
      dimension1: analyticsIdentifiers.isTestAccount,
      dimension2: analyticsIdentifiers.orgName,
      dimension3:
        productType === SurveyProductTypeEnum.RESIDENT
          ? analyticsIdentifiers.residentSolutionName
          : analyticsIdentifiers.solutionName,
      dimension4: analyticsIdentifiers.birthYearRange,
      dimension5: analyticsIdentifiers.levelOfCare,
      dimension6: analyticsIdentifiers.residentRespondentType,
      // Because of the redirect, this will be undefined except for initial load. That is okay since it's a session variable in GA
      dimension7: linkSource || '',
    })
  }
}

const SurveyContainer: React.FC = () => {
  const { t } = useTranslations()
  const routeParams = useParams<{
    participantId: string
    sourceFromUrl: ParticipantSourceTypeInput
  }>()
  let participantId = routeParams.participantId
  // Check if the user has selected for a specific participant among duplicated contact info.
  const { duplicateParticipantSelectionId } = useContext(DuplicateParticipantSelectionContext)
  if (duplicateParticipantSelectionId) {
    participantId = duplicateParticipantSelectionId
  }
  const sourceFromUrl = routeParams.sourceFromUrl

  let source: ParticipantSourceTypeInput = ParticipantSourceTypeInput.S
  if (
    sourceFromUrl &&
    Object.values(ParticipantSourceTypeInput).includes(sourceFromUrl as ParticipantSourceTypeInput)
  ) {
    source = sourceFromUrl as ParticipantSourceTypeInput
  }

  const result = useSurveyByParticipantIdQuery({
    variables: { participantId: participantId || '', source },
    skip: !participantId,
  })
  if (result.loading) {
    return <Loader />
  }
  const surveyByParticipantId = result?.data?.surveyByParticipantId
  if (!(participantId && surveyByParticipantId && surveyByParticipantId.questions)) {
    return <ErrorState message={t('Error: This survey code was not found.')} />
  }
  if (!surveyByParticipantId.location) {
    return <ErrorState message={t('Error: Location is missing for this participant.')} />
  }
  // If requested participant has duplicated contact info and the user has not yet seen it,
  // Show a duplicated contact info screen to allow them to choose which participant they are.
  // Also, bypass duplicate selection if the user entered their survey code from the Portal screen.
  if (
    surveyByParticipantId.duplicatedContactInfo?.length &&
    !duplicateParticipantSelectionId &&
    source !== ParticipantSourceTypeInput.L
  ) {
    return (
      <DuplicatedContactInfoScreen
        duplicatedContactInfo={surveyByParticipantId.duplicatedContactInfo}
        productType={surveyByParticipantId.productType}
      />
    )
  }
  setAnalyticsDimensions(
    participantId,
    result?.data?.participantAnalyticsIdentifiers,
    surveyByParticipantId.productType,
    source,
  )

  const {
    questions,
    levelOfCare,
    surveySubmitted,
    participantIsExpired,
    type,
    hasConfidentialResults,
    welcomeMessage,
    location,
    isOpenLinkSurvey,
    languages,
    translations,
  } = surveyByParticipantId

  let templates: { [key: string]: string } = {}
  if (surveyByParticipantId.templates) {
    templates = JSON.parse(surveyByParticipantId.templates)
    templates = Object.keys(templates).reduce(
      (acc, key) => ({ ...acc, [key]: t(templates[key] as TranslationKey) }),
      {},
    )
  }

  // Convert responses array to object {code: response}
  const responses: { [key: string]: string } = {}
  surveyByParticipantId.responses?.forEach(r => (responses[r.code] = r.response || ''))
  return (
    <SurveyRouter
      surveySubmitted={surveySubmitted}
      participantIsExpired={participantIsExpired}
      surveyType={type}
      surveyProductType={surveyByParticipantId.productType}
      hasConfidentialResults={hasConfidentialResults}
      participantId={participantId}
      questions={questions as PublicQuestions}
      levelOfCare={levelOfCare as LOCTypeEnum}
      templates={templates}
      responses={responses}
      welcomeMessage={welcomeMessage}
      onlineReviewSites={location?.onlineReviewSites}
      isOpenLinkSurvey={isOpenLinkSurvey}
      languages={languages}
      translations={translations}
      source={source}
    />
  )
}

export default SurveyContainer
