import React, { useContext, useState } from 'react'

import { makeStyles, createStyles, Theme } from '@material-ui/core'

import { DuplicateParticipantSelectionContext } from 'App'
import ErrorState from 'components/ErrorState'
import useGlobalStyles from 'components/lib/GlobalStyles'
import MultipleChoiceBlock from 'components/Survey/Blocks/MultipleChoiceBlock'
import { SurveyByParticipantIdQuery, SurveyProductTypeEnum } from 'generated/graphql'
import { TranslationKey, useTranslations } from 'locales'
import { desktopStyle } from 'utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      padding: '5%',
      '& >h4': {
        marginTop: '5%',
        color: theme.palette.common.white,
      },
      ...desktopStyle({
        paddingLeft: '20%',
        paddingRight: '20%',
      }),
    },
  }),
)

type DuplicatedContactInfo = NonNullable<
  NonNullable<SurveyByParticipantIdQuery['surveyByParticipantId']>['duplicatedContactInfo']
>[0]
const DuplicatedContactInfoScreen: React.FC<{
  duplicatedContactInfo: DuplicatedContactInfo[]
  productType: SurveyProductTypeEnum
}> = ({ duplicatedContactInfo, productType }) => {
  const classes = { ...useStyles(), ...useGlobalStyles() }
  const { setDuplicateParticipantSelectionId } = useContext(DuplicateParticipantSelectionContext)
  const { t } = useTranslations()
  const [selectedNoMatch, setSelectedNoMatch] = useState(false)
  if (selectedNoMatch) {
    // TODO: handle translation
    return (
      <ErrorState
        message={t(
          "We're sorry, you can't take the survey at this time. Please contact your survey administrator for assistance." as any,
        )}
      />
    )
  }

  const handleResponse = (selectedParticipantId: string) => {
    if (selectedParticipantId === 'noMatch') {
      setSelectedNoMatch(true)
      return
    }
    setDuplicateParticipantSelectionId(selectedParticipantId)
  }

  const getParticipantDescription = (contactInfo: DuplicatedContactInfo) => {
    const contactInfoKeyMap: Partial<DuplicatedContactInfo> = {
      fullName: 'Name',
      residentRespondentType: 'Relationship to Resident',
      jobTitle: 'Job Title',
      location: 'Location',
      department: 'Department',
    }
    let relevantFields: Array<keyof DuplicatedContactInfo> = []
    if (productType === SurveyProductTypeEnum.RESIDENT) {
      relevantFields = ['fullName', 'residentRespondentType']
    } else {
      relevantFields = ['fullName', 'jobTitle', 'location', 'department']
    }
    return (
      relevantFields
        .filter(key => contactInfo[key])
        // TODO: handle translation
        .map(key => `${contactInfoKeyMap[key]}: ${contactInfo[key]}`)
        .join(', ') as any
    )
  }

  const choices = [
    ...duplicatedContactInfo.map(contactInfo => ({
      code: contactInfo.participantId,
      text: getParticipantDescription(contactInfo) as TranslationKey,
    })),
    // TODO: handle translation
    { code: 'noMatch', text: t('None of these people match.' as any) as TranslationKey },
  ]

  // TODO: handle translation
  let title = t(
    'We have multiple people in our system with your same contact information. Can you confirm your identity and relationship to the resident?' as any,
  )
  if (productType === SurveyProductTypeEnum.EMPLOYEE) {
    title = t(
      'We have multiple people in our system with your same contact information. Can you confirm your identity?' as any,
    )
  }

  return (
    <div className={classes.screenContainer}>
      <div className={classes.body}>
        <MultipleChoiceBlock
          question={{
            text: title,
            benchmarkCode: 'duplicateText',
          }}
          onChange={handleResponse}
          displayHorizontal={false}
          isMultiselect={false}
          choices={choices}
        />
      </div>
    </div>
  )
}

export default DuplicatedContactInfoScreen
