import React, { useState } from 'react'

import { useParams } from 'react-router-dom'

import ErrorState from 'components/ErrorState'
import ExternalNavLink from 'components/lib/ExternalNavLink'
import Loader from 'components/lib/Loader'
import { PublicQuestions } from 'components/Survey/Survey'
import SurveyRouter from 'components/SurveyRouter'
import { useSurveyByIdQuery } from 'generated/graphql'
import { useTranslations, TranslationKey } from 'locales'
import { WALLE_URLS } from 'utils/constants'
import { LOCTypeEnum } from 'utils/generatedEnums'

const PreviewContainer: React.FC = () => {
  const { t } = useTranslations()
  const { surveyId } = useParams<{ surveyId: string }>()
  const [responses, setResponses] = useState<{ [key: string]: string }>({})

  const result = useSurveyByIdQuery({
    variables: { surveyId: surveyId || '' },
    skip: !surveyId,
  })
  if (result.loading) {
    return <Loader />
  }
  if (!surveyId || !result?.data?.surveyById) {
    return <ErrorState message={t('Error: URL missing a survey ID.')} />
  }
  if (!result?.data?.surveyById?.location) {
    return (
      <ErrorState
        message={
          <>
            {t('You need a location to preview the survey. Add locations')}{' '}
            <ExternalNavLink
              to={`${WALLE_URLS.SETTINGS.LOCATIONS}/${result?.data?.surveyById?.organization.locationFilterTypeUuid}`}
              label={t('here')}
            />
            .
          </>
        }
      />
    )
  }
  const {
    questions,
    levelOfCare,
    type,
    productType,
    welcomeMessage,
    location,
    hasConfidentialResults,
    isOpenLinkSurvey,
    languages,
    translations,
  } = result.data.surveyById

  let templates: { [key: string]: string } = {}
  if (result.data.surveyById.templates) {
    templates = JSON.parse(result.data.surveyById.templates)
    templates = Object.keys(templates).reduce(
      (acc, key) => ({ ...acc, [key]: t(templates[key] as TranslationKey) }),
      {},
    )
  }

  return (
    <SurveyRouter
      participantIsExpired={false}
      surveyType={type}
      surveyProductType={productType}
      isOpenLinkSurvey={isOpenLinkSurvey}
      hasConfidentialResults={hasConfidentialResults}
      questions={questions as PublicQuestions}
      levelOfCare={levelOfCare as LOCTypeEnum}
      templates={templates}
      responses={responses}
      welcomeMessage={welcomeMessage}
      updateResponses={(response: string, code: string) =>
        setResponses({ ...responses, [code]: response })
      }
      onlineReviewSites={location?.onlineReviewSites}
      languages={languages}
      translations={translations}
      isPreview
    />
  )
}

export default PreviewContainer
